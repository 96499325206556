import {createRouter, createWebHistory} from 'vue-router'
import i18n from "@/i18n";

const setLocale = (lang) => {
    i18n.global.locale.value = lang;
};

const routes = [
    {
        path: '/:lang',
        name: 'Home',
        component: () => import('../views/Home'),
    },
    {
        path: '/:lang/about',
        name: 'About',
        component: () => import('../views/About.vue'),
    },
    {
        path: '/:lang/services',
        name: 'Services',
        component: () => import('../views/Services'),
    },
    {
        path: '/:lang/contact',
        name: 'Contact',
        component: () => import('../views/Contact'),
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    }
})

router.beforeEach((to, from, next) => {
    const lang = to.params.lang; //Lang from path variable

    if (!['en', 'mk'].includes(lang)) { //supported languages
        if (!['about', 'contact', 'services'].includes(lang)) {
            if (lang === undefined) {
                if (localStorage.getItem('selectedLanguage')) {
                    return next({path: `/${localStorage.selectedLanguage}`})
                } else {
                    localStorage.setItem('selectedLanguage', 'en');
                }
            }

            localStorage.setItem('selectedLanguage', 'en');
            return next({path: '/en'})
        }

        let redirectTo = `/en${to.path}`;
        if (localStorage.getItem('selectedLanguage')) {
            setLocale(localStorage.getItem('selectedLanguage'));
            redirectTo = `/${localStorage.getItem('selectedLanguage')}${to.path}`;
        } else {
            setLocale("en");
            localStorage.setItem('selectedLanguage', 'en');
        }

        return next({path: redirectTo});
    } else {
        setLocale(lang);
        localStorage.setItem('selectedLanguage', lang);
        return next()
    }
});

export default router