<template>
    <div>
        <div class="push-menu">
            <div class="push-menu-inside">
                <div class="logo">
                    <img src="../assets/icons/logo.png" class="push-menu-logo" alt="">
                </div>
                <div class="links-placeholder">
                    <router-link :to="`/${this.$locale}`">
                        <div class="link">
                            <div class="left-part"></div>
                            <h4 class="link-text">{{ this.trans("NAV_ITEMS.HOME") }}</h4>
                            <div class="right-part"></div>
                        </div>
                    </router-link>
                    <router-link :to="`/${this.$locale}/services`">
                        <div class="link">
                            <div class="left-part"></div>
                            <h4 class="link-text">{{ this.trans("NAV_ITEMS.SERVICES") }}</h4>
                            <div class="right-part"></div>
                        </div>
                    </router-link>
                    <router-link :to="`/${this.$locale}/about`">
                        <div class="link">
                            <div class="left-part"></div>
                            <h4 class="link-text">{{ this.trans("NAV_ITEMS.ABOUT") }}</h4>
                            <div class="right-part"></div>
                        </div>
                    </router-link>
                    <router-link :to="`/${this.$locale}/contact`">
                        <div class="link">
                            <div class="left-part"></div>
                            <h4 class="link-text">{{ this.trans("NAV_ITEMS.CONTACT") }}</h4>
                            <div class="right-part"></div>
                        </div>
                    </router-link>
                </div>
                <div class="bottom-placeholder w-100">
                    <div class="language-selection-box mobile">
                        <a class="lang-link en" @click="changeLocale('en')">
                            English
                        </a>
                        <a class="lang-link mk" @click="changeLocale('mk')">
                            Македонски
                        </a>
                    </div>
                    <p class="copyright-text">
                        Copyright &copy; {{ new Date().getFullYear() }} - Casa Italia Global. <br>
                        {{ this.trans("FOOTER.ALL_RIGHTS_RESERVED") }}
                    </p>
                </div>
            </div>
        </div>
        <nav id="nav">
            <div class="nav-inside container">
                <div class="left-container d-none d-lg-flex">
                    <router-link class="nav-link" :to="`/${this.$locale}`">
                        <p class="nav-link-text">{{ this.trans("NAV_ITEMS.HOME") }}</p>
                        <div class="abstract-circle"></div>
                    </router-link>
                    <router-link class="nav-link" :to="`/${this.$locale}/services`">
                        <p class="nav-link-text">{{ this.trans("NAV_ITEMS.SERVICES") }}</p>
                        <div class="abstract-circle"></div>
                    </router-link>
                </div>
                <div class="center-container">
                    <router-link to="/">
                        <img class="nav-logo" src="../assets/icons/logo.png" alt="">
                    </router-link>
                </div>
                <div class="right-container d-none d-lg-flex">
                    <router-link class="nav-link" :to="`/${this.$locale}/about`">
                        <p class="nav-link-text">{{ this.trans("NAV_ITEMS.ABOUT") }}</p>
                        <div class="abstract-circle"></div>
                    </router-link>
                    <router-link class="nav-link" :to="`/${this.$locale}/contact`">
                        <p class="nav-link-text">{{ this.trans("NAV_ITEMS.CONTACT") }}</p>
                        <div class="abstract-circle"></div>
                    </router-link>
                </div>
                <div class="language-selection-box d-none d-lg-flex position-absolute">
                    <a class="lang-link en" @click="changeLocale('en')">
                        EN
                    </a>
                    <a class="lang-link mk" @click="changeLocale('mk')">
                        MK
                    </a>
                </div>
            </div>
        </nav>
        <div class="push-button" id="push-button" @click="togglePushMenu()">
            <div class="inside">
                <div class="dots">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
                <div class="x-lines hidden">
                    <div class="line-one"></div>
                    <div class="line-two"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import i18n from "@/i18n";

const setLocale = (lang) => {
    i18n.global.locale.value = lang;
};

export default {
    name: "Navigation",
    data: () => ({
        scrolledBeforeOpeningPush: 0,
    }),
    watch: {
        '$route'() {
            this.closePushMenu()
            this.$locale = localStorage.selectedLanguage
            this.$forceUpdate()
        }
    },
    mounted() {
        window.addEventListener('keyup', this.onKeyUp);
        window.addEventListener('resize', this.watchMenuOnResize);
    },
    unmounted() {
        window.removeEventListener('keyup', this.onKeyUp);
        window.removeEventListener('resize', this.watchMenuOnResize);
    },
    methods: {
        changeLocale(lang) {
            this.$locale = lang;
            setLocale(lang);

            $(".lang-link").removeClass("router-link-active");
            $(".lang-link." + lang).addClass("router-link-active");

            let path = this.$router.currentRoute.value.fullPath.split('/')

            if (!['en', 'mk'].includes(path[path.length - 1])) {
                this.$router.push({path: `/${lang}/${path[path.length - 1]}`})
            } else {
                this.$router.push({path: `/${lang}`})
            }
        },
        openPushMenu() {
            if (!$(".push-menu").hasClass("shown")) {
                this.scrolledBeforeOpeningPush = window.scrollY;
                $(".push-menu").addClass("shown");
                $(".push-button .inside .dots").addClass("hidden");
                $(".push-button .inside .x-lines").removeClass("hidden");
                setTimeout(function () {
                        $(".push-menu-inside").addClass("shown");
                        $(".push-menu .logo").addClass("shown");
                        $(".push-menu .link").addClass("shown");
                    },
                    150);
                setTimeout(function () {
                        $(".push-button").addClass("opened");
                        $(".push-menu .bottom-placeholder").addClass("shown");
                        $("html, body").addClass("no-scroll");
                    },
                    1000);
            }
        },
        closePushMenu() {
            if ($(".push-menu .bottom-placeholder").hasClass("shown")) {
                $("html, body").removeClass("no-scroll");
                $(window).scrollTop(this.scrolledBeforeOpeningPush);
                $(".push-menu-inside").removeClass("shown");
                $(".push-menu .logo").removeClass("shown");
                $(".push-menu .link").removeClass("shown");
                $(".push-menu .bottom-placeholder").removeClass("shown");
                $(".push-button .inside .x-lines").addClass("hidden");

                setTimeout(function () {
                        $(".push-menu").removeClass("shown");
                        $(".push-button .inside .dots").removeClass("hidden");
                        $(".push-button").removeClass("opened");
                    },
                    1500);
            }
        },
        togglePushMenu() {
            if ($(".push-button").hasClass("opened")) {
                this.closePushMenu();
            } else {
                this.openPushMenu();
            }
        },
        watchMenuOnResize() {
            if (window.innerWidth >= 991 && $(".push-menu").has("shown")) {
                this.closePushMenu();
            }
        },
        onKeyUp(event) {
            if (event.key === "Escape") {
                this.closePushMenu()
            }
        }
    },
}
</script>

<style scoped>

</style>