<template>
    <Navigation :class="{ 'opacity-0': isLoading }" />
    <router-view/>
    <Footer :class="{ 'opacity-0': isLoading }" />
</template>
<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
    components: {Footer, Navigation},
    name: "App",
    data() {
        return {
            isLoading: true,
        }
    },
    mounted() {
        this.setTitle()
        setTimeout(() => {
            this.isLoading = !this.isLoading
        }, 60)
    },
}
</script>